<template>
  <div>
    <div class="pricingtable_head">
      <div class="tagbox" v-if="isHeader">{{$t("sabre.booking.ticket-box.recommended")}}</div>
      <div class="d-block">
        <h3> {{ brandName }}</h3>
        <h4>{{$t("sabre.booking.ticket-box.additional-per-person", {price})}}</h4>
      </div>
    </div>
    <div class="pricingtable_body">
      <ul>
        <li v-for="(item, inx) in list" :key="inx">
          <i class="fa-solid fa-dollar" v-if="item && item === 'C'" style="color: red"></i>
          <i class="fa-solid fa-ban" v-else-if="item && item === 'D'" style="color: #8b0505"></i>
          <i class="fa-solid fa-check" v-else-if="item"></i>
          <i class="fa-solid fa-xmark" v-else></i>
        </li>
      </ul>
    </div>
    <div class="pricingtable_footer">
      <a href="javascript:void(0)" class="btn btn-outline-primary">{{$t("sabre.buttons.choose")}}</a>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'pricing-one-box',
  props: {
    isHeader: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    rightPlaceList: {
      type: Object,
      default: null,
    },
    diffPrice: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      brandFeatureDescs: 'GET_SABRE_BOOKING_BRAND_DESC',
    }),
    brandName() {
      const { data } = this;
      return data?.fare?.brand?.brandName || '';
    },
    list() {
      const { rightPlaceList, data, brandFeatureDescs } = this;
      const { brandFeatures } = data.fare;
      return Object.keys(rightPlaceList).map((key) => {
        const includedItem = rightPlaceList[key].find((elem) => brandFeatures.includes(elem));
        if (includedItem) {
          return brandFeatureDescs?.find((b) => b.id === includedItem).application;
        } else {
          return false;
        }
      });
    },
    totalPerson() {
      const { data } = this;
      return data?.fare?.passengerInfoList[0]?.passengerNumber || 2;
    },
    price() {
      const { diffPrice, totalPerson } = this;
      return totalPerson ? Math.round(diffPrice / totalPerson) : 0;
    },
  },
};
</script>
